import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f35cf6a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-account-members members-wrapper" }
const _hoisted_2 = { class: "avatar-group" }
const _hoisted_3 = { class: "invite-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortTeamMembers([...$setup.activeCustomerMembers, ...$setup.activeInternalMembers], 3), (item) => {
        return (_openBlock(), _createBlock($setup["UserAvatar"], {
          class: "members--avatar",
          key: item.id,
          id: item.userId.avatar,
          name: item.userId.firstName,
          size: 24
        }, null, 8, ["id", "name"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "invite",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('inviteMembers')))
      }, "Invite members")
    ])
  ]))
}